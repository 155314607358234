import unescape from 'lodash.unescape';

const CLASS_NAME = 'iframe-placeholder';

export default class IFramePlaceholder {
  public static findAll(): IFramePlaceholder[] {
    const phs = [];
    const phNodes = document.getElementsByClassName(CLASS_NAME);
    for (let i = 0; i < phNodes.length; i++) {
      const phNode = phNodes[i];
      if (phNode instanceof HTMLElement)
        phs.push(new IFramePlaceholder(phNode));
    }
    return phs;
  }

  public provider: string;
  public service: string;

  private _code: string;
  private _enabled = false;

  public constructor(private _element: HTMLElement) {
    this._code = this._findCode();

    const service = this._parseService();
    this.provider = service[0];
    this.service = service[1];
  }

  public init(): void {
    const clickListener = (e: MouseEvent): void => {
      if (e.target instanceof HTMLButtonElement) {
        this.enable();
        this._element.removeEventListener('click', clickListener);
      }
    };

    this._element.addEventListener('click', clickListener);
  }

  public enable(): void {
    if (this._enabled) return;

    this._enabled = true;
    this._element.innerHTML = this._code;
    this._element.className = CLASS_NAME;
  }

  private _parseService(): [string, string] {
    const service = this._element.dataset.service;
    if (!service) throw new Error('no service specified');

    const parts = service.split('/');
    if (parts.length !== 2) throw new Error('invalid service');

    return [parts[0], parts[1]];
  }

  private _findCode(): string {
    function findCode(root: Node): string | null {
      for (let n = root.firstChild; n; n = n.nextSibling) {
        if (n.nodeType === Node.ELEMENT_NODE) {
          const ret = findCode(n);
          if (ret) return ret;
        } else if (n.nodeType === Node.COMMENT_NODE) {
          if (n.nodeValue) return n.nodeValue;
        }
      }

      return null;
    }

    const code = findCode(this._element);
    if (!code) throw new Error('iframe code not found');
    return unescape(code);
  }
}
